import React from "react"
import YouTube from "react-player/youtube"
import styled from "styled-components"
import GastbyImage from "gatsby-image"

import { useScrollLock } from "./ScrollLockManager"
// import { InView } from 'react-intersection-observer';

const Container = styled.div`
  display: flex;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow-y: ${props => (props.lockScroll ? "hidden" : "auto")};
  -webkit-overflow-scrolling: ${props =>
    props.lockScroll ? "hidden" : "touch"};
  margin-bottom: ${props => props.theme.headerHeight};
  height: 500px;
  cursor: e-resize;
  @media (min-width: 280px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    /* background-color: lightblue; */
    height: 380px; /* IMMER AUCH UNTEN ANPASSEN */
  }

  @media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    /*background-color: brown;*/
    height: 480px;
  }
  @media (min-width: 641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones*/
    /*  background-color: blue;*/
    height: 400px;
  }
  @media (min-width: 961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    /* background-color: purple;*/
    height: 660px;
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    /*  background-color: yellow;*/
    height: 500px;
  }
  @media (min-width: 1281px) {
    /* hi-res laptops and desktops */
    /* background-color: pink;*/
    height: 680px;
  }
`

const Slide = styled.div`
  margin: 0;
  &:first-child {
    margin-left: 0;
  }
  flex: 1;
`
const Image = styled(GastbyImage)`
  width: ${props => props.ratio * 500}px;

  @media (min-width: 320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    /*  background-color: lightblue;*/
    width: ${props => props.ratio * 380}px; /* IMMER AUCH HIER ANPASSEN */
  }

  @media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    /* background-color: brown;*/
    width: ${props => props.ratio * 480}px;
  }
  @media (min-width: 641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones*/
    /* background-color: blue;*/
    width: ${props => props.ratio * 400}px;
  }
  @media (min-width: 961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    /* background-color: purple;*/
    width: ${props => props.ratio * 660}px;
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    /* background-color: yellow;*/
    width: ${props => props.ratio * 500}px;
  }
  @media (min-width: 1281px) {
    /* hi-res laptops and desktops */
    /*background-color: pink;*/
    width: ${props => props.ratio * 680}px;
  }
`
const Video = styled(Image)`
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
`

function Slides({ title, images, onViewChange, viewportHeight, viewportY, i }) {
  const containerRef = React.useRef(null)
  const [top, setTop] = React.useState(0)
  const [height, setHeight] = React.useState(0)
  const [lockScroll] = useScrollLock()

  React.useEffect(() => {
    if (containerRef) {
      setTop(containerRef.current.offsetTop)
      setHeight(containerRef.current.offsetHeight)
    }
  }, [containerRef])

  React.useEffect(() => {
    if (top) {
      const viewPercentage = Math.max(
        0,
        (Math.min(viewportY + viewportHeight, top + height) -
          Math.max(viewportY, top)) /
        height
      )
      onViewChange(viewPercentage)
    }
  }, [viewportHeight, viewportY, top, height])

  return (
    <div>
      <Container lockScroll={lockScroll} ref={containerRef}>
        {images.map(({ alt, src, video }, i) => (
          <Slide key={i}>
            <Image
              alt={alt}
              fluid={src.childImageSharp.fluid}
              ratio={src.childImageSharp.fluid.aspectRatio}
            />
            {video && video.length ? (
              <Video ratio={src.childImageSharp.fluid.aspectRatio} as="div">
                <YouTube
                  height="100%"
                  width="100%"
                  url={video}
                  controls
                />
              </Video>
            ) : null}
          </Slide>
        ))}
      </Container>
    </div>
  )
}

export default React.memo(Slides)
