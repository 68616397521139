import React from "react"
import styled from "styled-components"
import Draggable from "react-draggable"
import { useMedia } from "the-platform"

import Btn from "./button"

const WipBox = styled.div`
  position: fixed;
  color: black;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 25%;
  padding: 0.5%;
  bottom: 80%;
  left: 5%;
  z-index: 1;
  cursor: move;
  @media (min-width: 320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    /*background-color: lightblue;*/
    height: 5vh;
    left: 0%;
    width: 100vw;
    bottom: 0%;
  }
  @media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    /*background-color: brown;*/
    height: 5vh;
    left: 0%;
    width: 100vw;
    bottom: 0%;
  }
  @media (min-width: 641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones*/
    /* background-color: blue;*/
    height: 5vh;
    left: 0%;
    width: 100vw;
    bottom: 0%;
  }
  @media (min-width: 961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    /*background-color: purple;*/
    height: ${props => props.theme.headerHeight};
    width: 25vw;
    padding: 0.5%;
    bottom: 25vw;
    left: 15vw;
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    /* background-color: yellow;*/
    height: ${props => props.theme.headerHeight};
    width: 25vw;
    padding: 0.5%;
    bottom: 25vw;
    left: 15vw;
  }
  @media (min-width: 1281px) {
    /* hi-res laptops and desktops */
    /*background-color: pink;*/
    height: ${props => props.theme.headerHeight};
    width: 25vw;
    padding: 0.5%;
    bottom: 30vw;
    left: 15vw;
  }
`

function preventDefault(e) {
  e.preventDefault()
  e.stopPropagation()
}

function disableBodyScroll() {
  document.body.addEventListener("touchmove", preventDefault, {
    passive: false,
  })
  document.addEventListener("scroll", preventDefault, {
    passive: false,
  })
}
function enableBodyScroll() {
  document.body.removeEventListener("touchmove", preventDefault)
  document.removeEventListener("scroll", preventDefault)
}

const Wip = ({ project }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isDragging, setIsDragging] = React.useState(false)
  const isMobile = useMedia({ maxWidth: 960 })

  React.useEffect(() => {
    if (isOpen && !isMobile) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
    return enableBodyScroll
  }, [isOpen, isMobile])

  React.useEffect(() => {
    setIsOpen(false)
  }, [project])

  return isMobile ? (
    <WipBox>
      »{project.title}«<Btn to={project.slug}>OPEN</Btn>
    </WipBox>
  ) : (
    <Draggable
      onDrag={() => setIsDragging(true)}
      onStop={() => {
        if (!isDragging) {
          setIsOpen(true)
        }
        setIsDragging(false)
      }}
      bounds="parent"
    >
      <WipBox>
        »{project.title}«<Btn to={project.slug}>OPEN</Btn>
      </WipBox>
    </Draggable>
  )
}

export default Wip
