import React from "react"
import throttle from "./throttle"

const events = new Set()
const onResize = () => events.forEach(fn => fn())

export const useWindowSize = (options = {}) => {
  const { throttleMs = 100 } = options
  const [size, setSize] = React.useState({
    width: typeof window === "undefined" ? 0 : window.innerWidth,
    height: typeof window === "undefined" ? 0 : window.innerHeight,
  })

  const handle = throttle(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, throttleMs)

  React.useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    if (events.size === 0) {
      window.addEventListener("resize", onResize, true)
    }

    events.add(handle)

    return () => {
      events.delete(handle)

      if (events.size === 0) {
        window.removeEventListener("resize", onResize, true)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return size
}

export default useWindowSize;
