import React from "react"
import { graphql } from "gatsby"
import useWindowScrollPosition from "../utils/useWindowScrollPosition"
import useWindowSize from "../utils/useWindowSize"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slides from "../components/slides"
import WipBar from "../components/wip/wip-bar"

const IndexPage = ({ data }) => {
  const [views, setViews] = React.useState(
    new Array(data.allMarkdownRemark.edges.length).fill(0)
  )
  const visibleProjectIndex = views.indexOf(Math.max(...views))
  const setViewPercentage = sectionId => p =>
    setViews(prevState => prevState.map((x, id) => (sectionId === id ? p : x)))

  const { y } = useWindowScrollPosition()
  const { height } = useWindowSize()

  return (
    <Layout>
      <SEO
        title=" ⠀ "
        keywords={[
          `Julia`,
          `Rosenstock`,
          `Portfolio`,
          `Berlin`,
          `Fotografie`,
          `Photography`,
          `Artdirection`,
          `Iloveyou`,
          `Magazine`,
          `UDK`,
          `Gaabs`,
        ]}
      />
      {data.allMarkdownRemark.edges
        .filter(({ node }) => node.frontmatter.published)
        .map(({ node }, i) => (
          <React.Fragment key={node.frontmatter.title}>
            <Slides
              viewportHeight={height}
              viewportY={y}
              title={node.frontmatter.title}
              images={node.frontmatter.slider.images}
              onViewChange={setViewPercentage(i)}
              i={i}
            />
          </React.Fragment>
        ))}
      <WipBar
        project={
          data.allMarkdownRemark.edges[visibleProjectIndex].node.frontmatter
        }
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { eq: true }  }}
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            published
            slug
            slider {
              images {
                src {
                  childImageSharp {
                    fluid(maxHeight: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
                video
              }
            }
          }
        }
      }
    }
  }
`
